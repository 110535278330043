import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../store/store";
import {
  ChangeAnswerEditingTextType,
  GPTAnswerType,
  NewGptAnswersType,
} from "../../../../../store/features/answer/answerTypes";
import {
  changeAnswerEditingTextAC,
  changeIsSeenAC,
} from "../../../../../store/features/answer/answerSlice";
import { GreenLightV1 } from "../../../../../components/greenLight/greenLight";
import TextAreaAutoResizable from "../../../../../components/textAreaAutoResize/textAreaAutoResize";
import { useEffect } from "react";
import { setNoticeNewAnswerGPT } from "../../../../../store/features/questionSlice";
import { useParams } from "react-router-dom";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import s from "./gptAnswerTextFileds.module.css";

type GptAnswerTextFieldsPropsType = {
  answerId: string;
  gptAnswer: GPTAnswerType;
};
const GptAnswerTextFields = (props: GptAnswerTextFieldsPropsType) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentCategoryId = params.categoryId || "";
  const answersFullText: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersFullTextMode
  );
  const isThisAnswerFullText: boolean = answersFullText.some(
    (el) => el === props.gptAnswer.answerId
  );
  useEffect(() => {
    if (!props.gptAnswer.isSeen) {
      dispatch(setNoticeNewAnswerGPT({ id: currentCategoryId, value: false }));
      setTimeout(() => {
        dispatch(changeIsSeenAC({ id: props.gptAnswer.answerId }));
      }, 2000);
    }
  }, [props.gptAnswer.isSeen]);

  const newGptAnswers: Array<NewGptAnswersType> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.newGptAnswers
  );
  const isHereNewGptAnswer: boolean = newGptAnswers.some(
    (el) => el.answerId === props.gptAnswer.answerId
  );

  const answersTextEditing: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersTextEditing
  );
  const isTextEditing: boolean = answersTextEditing.some(
    (el) => el === props.gptAnswer.answerId
  );

  const newAnswerTextArray = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.editingTextArray
  );
  const newAnswerText: string =
    newAnswerTextArray.find((el) => el.answerId === props.answerId)?.newText ||
    "";

  const onTextEditHandler = (newText: string) => {
    const data: ChangeAnswerEditingTextType = {
      answerId: props.answerId,
      newText: newText,
    };
    dispatch(changeAnswerEditingTextAC(data));
  };
  useEffect(() => {
    const data: ChangeAnswerEditingTextType = {
      answerId: props.answerId,
      newText: props.gptAnswer.gptResponse,
    };
    dispatch(changeAnswerEditingTextAC(data));
  }, [dispatch, props.answerId, props.gptAnswer.gptResponse]);

  return (
    <div className={s.textSection}>
      <div className={s.titleDiv}>
        {!props.gptAnswer.isSeen && <GreenLightV1 />}
        {props.gptAnswer.dateTime}
      </div>

      { isTextEditing
          ?<TextAreaAutoResizable
            onChangeFunction={(newText: string) => onTextEditHandler(newText)}
            text={newAnswerText}
            isDisabled={!isTextEditing}
          />
          : 
            <div id={props.answerId}>
              <Markdown remarkPlugins={[remarkGfm]} className={s.markdown_class}>
                {newAnswerText}
              </Markdown>
            </div>
          
      }
    </div>
  );
};

export default GptAnswerTextFields;
