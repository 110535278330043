import { AxiosResponse } from "axios";

import {
  PostGptHistoryFavoriteThunkRequestType,
} from "../../store/features/answer/answer-history-thunk";
import { PostAddTemplateThunkRequestType } from "../../store/features/questionThunk";
import {
  CheckPromoResponseType,
  GetProductsThunkResType,
  PayProductRequestType,
} from "../../store/features/productThunk";

import { instance } from "./instance";

export const categoriesAPI = {
  getCategories: (): Promise<AxiosResponse> => {
    return instance.get("question/categories");
  },
};

export const questionsAPI = {
  getQuestions_v2: (categoryId: string): Promise<AxiosResponse> => {
    return instance.get("question/questions?categoryId=" + categoryId);
  },
};

export const templatesAPI = {
  getTemplates: (category_id: string): Promise<AxiosResponse> => {
    return instance.get("templates/get?category_id=" + category_id);
  },
  deleteTemplate: (templateId: string): Promise<AxiosResponse> => {
    return instance.delete("templates?templateId=" + templateId);
  },
  postAddTemplate: (
    data: PostAddTemplateThunkRequestType
  ): Promise<AxiosResponse> => {
    return instance.post("templates/add", data);
  },
};

export const historyAPI = {
  getGptHistory: (categoryId: string): Promise<AxiosResponse> => {
    return instance.get("history/gptHistory?categoryId=" + categoryId);
  },
  postGptHistoryFavorite: (
    data: PostGptHistoryFavoriteThunkRequestType
  ): Promise<AxiosResponse> => {
    return instance.post("history/gptHistoryFavorite", data);
  },
  deleteGptHistoryFavorite: (id: string): Promise<AxiosResponse> => {
    return instance.delete("history/gptHistoryFavorite?id=" + id);
  },
};

export const productAPI = {
  // getProducts: (): Promise<AxiosResponse<ProductsResponse<GetProductsThunkResType>>> => {
  getProducts: (): Promise<AxiosResponse<GetProductsThunkResType>> => {
    return instance.get("pay/products");
  },
  postPromo: (
    promoCode: string
  ): Promise<AxiosResponse<CheckPromoResponseType>> => {
    return instance.post("pay/promo", { promo_code: promoCode });
  },
  postPayUrl: (data: PayProductRequestType): Promise<AxiosResponse> => {
    return instance.post("pay/url", data);
  },
};
