import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { answerInitContent } from "../../storeConst/vars";
import {
  AnswerInitContentType,
  ChangeAnswerEditingTextType,
  ChangeOneAnswerResponseType,
  GPTAnswerType,
  GptHistoryType,
  HistorySearchFavTypes,
} from "./answerTypes";
import {
  deleteGptHistoryFavoriteThunk,
  getGptHistoryThunk,
  postGptHistoryFavoriteThunk,
} from "./answer-history-thunk";
import dayjs from "dayjs";
import { COMMON_DATE_TIME_FORMAT } from "../../storeConst/consts";
import uuid from "react-uuid";
import { chatGptSendThunk, PostReceiveGptResponseV2Type } from "./answer-gpt-chat-thunks";
import { sendVisionGPTThunk } from "./answer-gpt-vision-thunk";
import { sendDocPdfGptThunk } from "./answer-gpt-doc-thunks";

export const answerSlice = createSlice({
  name: "answerSlice",
  initialState: answerInitContent,
  reducers: {
    //Удаление одного конкретного ответа GPT. Используется в кнопках сверху каждого ответа
    removeGptAnswerAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.answers = state.answers.filter(
        (el) => el.answerId !== action.payload
      );
    },
    //Редактирование (добавление) списка ответов, котрые показаны полностью
    addFullTextAnswerAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptAnswerVars.answersFullTextMode = [
        ...state.gptAnswerVars.answersFullTextMode,
        action.payload,
      ];
    },
    //Редактирование (удаление) списка ответов, котрые показаны полностью
    removeFullTextAnswerAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptAnswerVars.answersFullTextMode =
        state.gptAnswerVars.answersFullTextMode.filter(
          (el) => el !== action.payload
        );
    },
    //Редактирование (добавление) списка ответов, котрые находятся в стадии редактирования текста
    addTextEditingAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptAnswerVars.answersTextEditing = [
        ...state.gptAnswerVars.answersTextEditing,
        action.payload,
      ];
    },
    //Редактирование (удаление) списка ответов, котрые находятся в стадии редактирования текста
    removeTextEditingAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptAnswerVars.answersTextEditing =
        state.gptAnswerVars.answersTextEditing.filter(
          (el) => el !== action.payload
        );
    },
    changeIsSeenAC: (
      state: AnswerInitContentType,
      action: PayloadAction<{ id: string }>
    ) => {
      state.answers = state.answers.map((a) => {
        if (a.answerId === action.payload.id) {
          return { ...a, isSeen: true };
        }
        return a;
      });
    },
    //Текст ответа, который редактируется. Этот текст показан в редакторе, когда кнопка "карандаш" нажата
    changeAnswerEditingTextAC: (
      state: AnswerInitContentType,
      action: PayloadAction<ChangeAnswerEditingTextType>
    ) => {
      const isPresented: boolean = state.gptAnswerVars.editingTextArray.some(
        (el) => el.answerId === action.payload.answerId
      );
      if (isPresented) {
        state.gptAnswerVars.editingTextArray = [
          ...state.gptAnswerVars.editingTextArray,
        ].map((el) => {
          if (el.answerId === action.payload.answerId) {
            return { ...el, newText: action.payload.newText };
          }
          return el;
        });
      } else {
        state.gptAnswerVars.editingTextArray = [
          ...state.gptAnswerVars.editingTextArray,
          action.payload,
        ];
      }
    },
    //Когда редактирование текста ответа завершено, он сохраняется в основном списке
    changeGptAnswerResponseTextAC: (
      state: AnswerInitContentType,
      action: PayloadAction<ChangeOneAnswerResponseType>
    ) => {
      state.answers = [...state.answers].map((ans) => {
        if (ans.answerId === action.payload.answerId) {
          return { ...ans, gptResponse: action.payload.newAnswerText };
        }
        return ans;
      });
    },

    //Когда призходит новый ответ GPT мы обозначаем его зеленым кружком. AC для удаления этого кружка
    removeItemFromNewGptAnswerAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptAnswerVars.newGptAnswers =
        state.gptAnswerVars.newGptAnswers.filter(
          (el) => el.answerId !== action.payload
        );
    },

    //Здесь и ниже обработка поиска по истории ответов GPT
    changeUserAnsSearchAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptHistorySearchVars.userAnswerTextSearch = action.payload;
    },
    changeGptTextSearchAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.gptHistorySearchVars.gptTextSearch = action.payload;
    },
    changeDateStartSearchAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string | null>
    ) => {
      state.gptHistorySearchVars.dateStartSearch = action.payload;
    },
    changeDateEndSearchAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string | null>
    ) => {
      state.gptHistorySearchVars.dateEndSearch = action.payload;
    },
    changeHistorySearchFavAC: (
      state: AnswerInitContentType,
      action: PayloadAction<HistorySearchFavTypes>
    ) => {
      state.gptHistorySearchVars.favoriteSearch = action.payload;
    },

    //В зависимости от того, какая подкатегория ждет ответ PGT мы показывваем прелоадер
    addWhichCategoryGptLoadingAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.loadingVars.whichCategoryGptAnswerLoading = [
        ...state.loadingVars.whichCategoryGptAnswerLoading,
        action.payload,
      ];
    },
    //В зависимости от того, какая подкатегория ждет ответ PGT мы показывваем прелоадер
    removeWhichCategoryGptLoadingAC: (
      state: AnswerInitContentType,
      action: PayloadAction<string>
    ) => {
      state.loadingVars.whichCategoryGptAnswerLoading = [
        ...state.loadingVars.whichCategoryGptAnswerLoading,
      ].filter((el) => el !== action.payload);
    },

    //Если пользователь запросил ответ GPT, но сервер ответил, что у него не оплачен тариф или тариф закончился
    changeIsLimitationOnAC: (
      state: AnswerInitContentType,
      action: PayloadAction<boolean>
    ) => {
      state.gptAnswerVars.isLimitationOn = action.payload;
    },
  },

  extraReducers: (builder) => {
    //Запрос на получение ответа GPT с сервера
    builder.addCase(
      chatGptSendThunk.fulfilled,
      (
        state: AnswerInitContentType,
        action: PayloadAction<PostReceiveGptResponseV2Type>
      ) => {
        if (action.payload) {
          const currentDate: string = dayjs(new Date()).format(
            COMMON_DATE_TIME_FORMAT
          );
          const newItem: GPTAnswerType = {
            answerId: action.payload.gpt_answer_id || uuid(),
            categoryId: action.payload.categoryId,
            questions: [],
            isSeen: false,
            dateTime: currentDate,
            gptResponse: action.payload.gpt_answer || "",
            responseTitle: currentDate,
          };

          //Добавляем новый ответ в список ответов:
          const currentAnswers: Array<GPTAnswerType> = [
            newItem,
            ...state.answers,
          ].sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1));
          state.answers = currentAnswers;

          //Новый ответ показан полностью, а другие ответы в этой же подкатегории показаны в сокращенном варианте
          const newAnswersFullTextMode: Array<string> = [
            ...state.gptAnswerVars.answersFullTextMode,
          ].filter((el) => {
            const ansCategoryId: string | undefined = currentAnswers.find(
              (ans) => ans.answerId === el
            )?.categoryId;
            return ansCategoryId !== action.payload.categoryId;
          });
          state.gptAnswerVars.answersFullTextMode = [
            ...newAnswersFullTextMode,
            newItem.answerId,
          ];

          //Добавляем зеленый кружочек в сам ответ и в категорию, где этот ответ расположен
          state.gptAnswerVars.newGptAnswers = [
            ...state.gptAnswerVars.newGptAnswers,
            {
              categoryId: action.payload.categoryId,
              answerId: action.payload.gpt_answer_id,
            },
          ];
        }
      }
    );
    builder.addCase(sendVisionGPTThunk.fulfilled, (state, action) => {
      const answerId = uuid();
      const currentDate: string = dayjs(new Date()).format(
        COMMON_DATE_TIME_FORMAT
      );
      const newItem: GPTAnswerType = {
        answerId,
        categoryId: action.payload.category_id,
        questions: [],
        isSeen: false,
        dateTime: currentDate,
        gptResponse: action.payload.content,
        img_url: action.payload.img_url,
        responseTitle: currentDate,
      };

      const currentAnswers: Array<GPTAnswerType> = [
        newItem,
        ...state.answers,
      ].sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1));
      state.answers = currentAnswers;

      const newAnswersFullTextMode: Array<string> = [
        ...state.gptAnswerVars.answersFullTextMode,
      ].filter((el) => {
        const ansCategoryId: string | undefined = currentAnswers.find(
          (ans) => ans.answerId === el
        )?.categoryId;
        return ansCategoryId !== action.payload.category_id;
      });

      state.gptAnswerVars.answersFullTextMode = [
        ...newAnswersFullTextMode,
        newItem.answerId,
      ];
      //Добавляем зеленый кружочек в сам ответ и в категорию, где этот ответ расположен
      state.gptAnswerVars.newGptAnswers = [
        ...state.gptAnswerVars.newGptAnswers,
        {
          categoryId: action.payload.category_id,
          answerId,
        },
      ];
    });

    //Обработка запрос по общению к PDF/DOC
    builder.addCase(sendDocPdfGptThunk.fulfilled, (state, action) => {
      const answerId = uuid();
      const currentDate: string = dayjs(new Date()).format(
        COMMON_DATE_TIME_FORMAT
      );
      const newItem: GPTAnswerType = {
        answerId,
        categoryId: action.payload.category_id,
        questions: [],
        isSeen: false,
        dateTime: currentDate,
        gptResponse: action.payload.content,
        // img_url: action.payload.img_url,
        responseTitle: currentDate,
      };

      const currentAnswers: Array<GPTAnswerType> = [
        newItem,
        ...state.answers,
      ].sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1));
      state.answers = currentAnswers;

      const newAnswersFullTextMode: Array<string> = [
        ...state.gptAnswerVars.answersFullTextMode,
      ].filter((el) => {
        const ansCategoryId: string | undefined = currentAnswers.find(
          (ans) => ans.answerId === el
        )?.categoryId;
        return ansCategoryId !== action.payload.category_id;
      });

      state.gptAnswerVars.answersFullTextMode = [
        ...newAnswersFullTextMode,
        newItem.answerId,
      ];
      //Добавляем зеленый кружочек в сам ответ и в категорию, где этот ответ расположен
      state.gptAnswerVars.newGptAnswers = [
        ...state.gptAnswerVars.newGptAnswers,
        {
          categoryId: action.payload.category_id,
          answerId,
        },
      ];
    });

    //Обрабока запроса на получение истории запросов-ответов GPT
    builder.addCase(
      getGptHistoryThunk.pending,
      (state: AnswerInitContentType) => {
        state.loadingVars.isHistoryLoading = true;
      }
    );
    builder.addCase(
      getGptHistoryThunk.fulfilled,
      (
        state: AnswerInitContentType,
        action: PayloadAction<Array<GptHistoryType>>
      ) => {
        if (action.payload && action.payload.length > 0) {
          state.gptHistory = action.payload.map((history) => {
            return {
              ...history,
              questions: history.questions.sort(
                (a, b) => a.order_index - b.order_index
              ),
            };
          });
        }
        state.loadingVars.isHistoryLoading = false;
      }
    );
    builder.addCase(
      getGptHistoryThunk.rejected,
      (state: AnswerInitContentType) => {
        state.loadingVars.isHistoryLoading = false;
      }
    );

    //Обработка добавления в избранное одной записи Истории GPT
    //в response нам призодит полная история запросов-ответов GPT
    builder.addCase(
      postGptHistoryFavoriteThunk.pending,
      (state: AnswerInitContentType) => {
        state.loadingVars.isHistoryLoading = true;
      }
    );
    builder.addCase(
      postGptHistoryFavoriteThunk.fulfilled,
      (
        state: AnswerInitContentType,
        action: PayloadAction<Array<GptHistoryType>>
      ) => {
        if (action.payload && action.payload.length > 0) {
          state.gptHistory = action.payload;
        }
        state.loadingVars.isHistoryLoading = false;
      }
    );
    builder.addCase(
      postGptHistoryFavoriteThunk.rejected,
      (state: AnswerInitContentType) => {
        state.loadingVars.isHistoryLoading = false;
      }
    );

    //Обработка удаления из избранного одной записи Истории GPT
    //в response нам призодит полная история запросов-ответов GPT
    builder.addCase(
      deleteGptHistoryFavoriteThunk.pending,
      (state: AnswerInitContentType) => {
        state.loadingVars.isHistoryLoading = true;
      }
    );
    builder.addCase(
      deleteGptHistoryFavoriteThunk.fulfilled,
      (
        state: AnswerInitContentType,
        action: PayloadAction<Array<GptHistoryType>>
      ) => {
        if (action.payload && action.payload.length > 0) {
          state.gptHistory = action.payload;
        }
        state.loadingVars.isHistoryLoading = false;
      }
    );
    builder.addCase(
      deleteGptHistoryFavoriteThunk.rejected,
      (state: AnswerInitContentType) => {
        state.loadingVars.isHistoryLoading = false;
      }
    );
  },
});
export const {
  removeGptAnswerAC,
  addFullTextAnswerAC,
  removeFullTextAnswerAC,
  addTextEditingAC,
  removeTextEditingAC,
  changeGptAnswerResponseTextAC,
  removeItemFromNewGptAnswerAC,
  changeUserAnsSearchAC,
  changeGptTextSearchAC,
  changeDateStartSearchAC,
  changeDateEndSearchAC,
  changeHistorySearchFavAC,
  addWhichCategoryGptLoadingAC,
  removeWhichCategoryGptLoadingAC,
  changeAnswerEditingTextAC,
  changeIsLimitationOnAC,
  changeIsSeenAC,
} = answerSlice.actions;

export default answerSlice.reducer;
