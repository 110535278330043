import JoyRide, { CallBackProps, Step } from "react-joyride";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { appSelectors } from "../../store/features/app/app-selectors";
import "./onboarding.scss";
import { OnboardingTooltip } from "./tooltip/tooltip";
import { appActions } from "../../store/features/app/app-slice";
import { OnboardingStartSteps } from "./steps/OnboardingStartSteps";
import { useEffect, useRef, useState } from "react";
import {
  OnboardingStatusVariant,
  OnboardingType,
  OnboardingVariant,
} from "../../store/features/app/app-types";
import { changeStatusEducation } from "../../store/features/profile/profile-thunk";

export const Onboarding = () => {
  const dispatch = useAppDispatch();
  const { stepIndex, run, status_education, variantEducation } = useAppSelector(
    appSelectors.getOnboarding()
  );

  const [steps, setSteps] = useState<Step[]>();
  const isFirstUpdate = useRef(true);
  useEffect(() => {
    isFirstUpdate.current
      ? (isFirstUpdate.current = false)
      : dispatch(
          changeStatusEducation({ status_education: status_education! })
        );
  }, [status_education]);
  const set = (data: Partial<OnboardingType>) =>
    dispatch(appActions.setOnboarding({ ...data }));
 
  const handleCallback = (data: CallBackProps) => {
    const { action, index, type } = data;
    if (variantEducation !== OnboardingVariant.WithoutTariff) {
      if (type === "step:after" && index === 8) {
        set({ run: false });
        dispatch(appActions.toggleSideBar(true));
        setTimeout(() => {
          set({ run: true });
        }, 300);
      }
    }
    if (variantEducation === OnboardingVariant.WithoutTariff) {
      if (type === "step:before" && index === 4) {
        // пропускаем шаг с символами
        set({ run: false });
        set({ stepIndex: 5 });
        set({ run: true });
      }
      if (type === "step:after" && index === 9) {
        // завершаем обучение если нет тарифа
        set({
          tourActive: false,
          stepIndex: 0,
          run: false,
          status_education: OnboardingStatusVariant.Later,
        });
      }
    }

    if (type === "step:after" && index === 11) {
      set({ run: false });
      dispatch(
        appActions.setOpenOneCategory({
          id: "9021c0e4-faba-4865-994b-05917d113df3",
        })
      );
      set({ run: true });
    }
    // if (type === "step:after" && index === 13) {
    //   set({ run: false });
    // }

    // if (
    //   !devWidth.isScreenLg &&
    //   status_education !== OnboardingStatusVariant.Later
    // ) {
    //   set({ status_education: OnboardingStatusVariant.Later });
    // }
    if (type === "step:after" && action === "next") {
      set({ stepIndex: index + 1 });
    }
    if (type === "step:after" && index === 15) {
      set({ status_education: OnboardingStatusVariant.Completed }); // последний шаг завершаем обучение
    }

    if (action === ("stop" && "close" && "skip")) {
      set({
        tourActive: false,
        stepIndex: 0,
        run: false,
        status_education: OnboardingStatusVariant.Later,
      });
    }
  };

  return (
    <>
      <JoyRide
        continuous
        stepIndex={stepIndex}
        steps={steps}
        disableOverlayClose
        spotlightClicks={false}
        scrollToFirstStep
        showSkipButton
        scrollOffset={150}
        spotlightPadding={15}
        tooltipComponent={OnboardingTooltip}
        locale={{ next: "Далее" }}
        callback={handleCallback}
        run={run}
        styles={{
          options: {
            arrowColor: "#3d4249",
            overlayColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      />
      <OnboardingStartSteps setSteps={setSteps} />
    </>
  );
};
