import { useParams } from "react-router-dom";
import AnswerGPT from "./answer/answer";
import Questions from "./questions/questions";
import { CategoryType } from "../../store/features/questionTypes";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import {
  getQuestionsThunk,
  getTemplatesThunk,
} from "../../store/features/questionThunk";
import MyTemplates from "./questions/templates/myTemplates/myTemplates";
import { Paths } from "../../common/router/paths";
import CategoryBackButton from "./backButton/backButton";
import { changeCurrentGptTypeAC } from "../../store/features/questionSlice";

import s from "./contentBlockSubCatV2.module.css";

const ContentBlockSubCategoryV2 = () => {
  const dispatch = useAppDispatch();
  const param = useParams();
  const { categoryId } = param || "";
  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );
  const currentCategory: CategoryType | undefined = categories.find(
    (cat) => cat.id === categoryId
  );
  const categoryTitle: string | undefined = currentCategory?.title;
  const categoryDescription: string | undefined = currentCategory?.description;
  const parentCategoryId: string | undefined = currentCategory?.parentId;

  const [isShowMyTemplates, setIsShowMyTemplates] = useState<boolean>(false);

  const showQuesionsBlock: boolean = useSelector(
    (state: RootState) => state.parametr.contentBlockQuestionsShow
  );
  const subCatDisplay: string = showQuesionsBlock
    ? s.subCatDisplay + " " + s.subCatDisplayTemplateQuestionsShow
    : s.subCatDisplay + " " + s.subCatDisplayTemplateQuestionsHide;

  useEffect(() => {
    dispatch(getQuestionsThunk({ categoryId: categoryId! }));
    dispatch(getTemplatesThunk(categoryId!));
  }, []);

  useEffect(() => {
    dispatch(changeCurrentGptTypeAC(currentCategory?.type_gpt || null))
  }, [currentCategory?.id])

  return (
    <>
      <MyTemplates
        show={isShowMyTemplates}
        onCloseFunction={() => setIsShowMyTemplates(false)}
      />
      <div className={s.contentSubCatWrapper}>
        <div className={s.subCatTitleDiv}>
          <div className={s.subCatTitleDiv_title}>
            <div className={s.subCatBackTitle_div}>
              <CategoryBackButton
                path={Paths.category + "/" + parentCategoryId}
              />
              <h3>{categoryTitle}</h3>
            </div>
            {categoryDescription}
            <div className={s.subCatDescriptionNotice}>
              * Если первая версия текста вас не устроила – повторяйте генерацию
              до нужного результата. Обычно требуется 2-4 нажатия.
            </div>
          </div>
        </div>
        <div className={subCatDisplay}>
          <div className={s.contentSection}>
            {/* <Questions type_gpt={currentCategory!.type_gpt} /> */}
            <Questions />
          </div>
          <div className={s.contentSection}>
            <AnswerGPT type_gpt={currentCategory!.type_gpt} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentBlockSubCategoryV2;
