import { GptHistoryType } from "../../../store/features/answer/answerTypes";
import { QuestionType } from "../../../store/features/questionTypes";
import s from "./oneHistorySection.module.css";

import favorityWhiteEmptyIcon from "../../../assets/icons/gptHistory/favorite_icon_empty_white.png";
import favorityRedIcon from "../../../assets/icons/gptHistory/favorite_icon_red.png";
import arrowUpIcon from "../../../assets/icons/gptHistory/up_arrow_icon.png";
import arrowDownIcon from "../../../assets/icons/gptHistory/down_arrow_icon.png";

import { useState } from "react";
import { useAppDispatch } from "../../../store/store";
import {
  deleteGptHistoryFavoriteThunk,
  postGptHistoryFavoriteThunk,
} from "../../../store/features/answer/answer-history-thunk";
import dayjs from "dayjs";
import {
  COMMON_DATE_TIME_FORMAT,
  QUESTION_OPTION_TYPE,
} from "../../../store/storeConst/consts";
import {
  ResizeType,
  useResize,
} from "../../../common/hooks/use-resize/useResize";

type OneHistorySectionPropsType = {
  h: GptHistoryType;
  index: number;
};
const OneHistorySection = ({ h }: OneHistorySectionPropsType) => {
  const dispatch = useAppDispatch();
  const [isShow, setIsShow] = useState<boolean>(false);

  const devWidth: ResizeType = useResize();
  const wrapperClass: string = isShow
    ? s.oneHistorySection + " " + s.oneHistorySectionLong
    : s.oneHistorySection + " " + s.oneHistorySectionShort;

  const onUpArrowClickHandler = () => {
    setIsShow(false);
  };
  const onDownArrowClickHandler = () => {
    setIsShow(true);
  };
  const onAddFavoriteClickHandler = (id: string) => {
    dispatch(postGptHistoryFavoriteThunk({ id: id }));
  };
  const onRemoveFavoriteClickHandler = (id: string) => {
    dispatch(deleteGptHistoryFavoriteThunk({ id: id }));
  };
  return (
    <div className={wrapperClass}>
      <div className={s.oneHistoryField}>
        {isShow ? (
          <div className={s.oneHistoryField_mobile_show}>
            <img alt="" src={arrowUpIcon} onClick={onUpArrowClickHandler} />
            {devWidth.width < 595 && (
              <>{dayjs(h.dateTime).format(COMMON_DATE_TIME_FORMAT)} </>
            )}
          </div>
        ) : (
          <div className={s.oneHistoryField_mobile_show}>
            <img alt="" src={arrowDownIcon} onClick={onDownArrowClickHandler} />
            {devWidth.width < 595 && (
              <>{dayjs(h.dateTime).format(COMMON_DATE_TIME_FORMAT)} </>
            )}
          </div>
        )}
        {/* {dayjs(props.h.dateTime).format(COMMON_DATE_TIME_FORMAT)} */}
      </div>
      <div className={s.oneHistoryField}>
        {devWidth.width > 529 && (
          <>{dayjs(h.dateTime).format(COMMON_DATE_TIME_FORMAT)}</>
        )}
      </div>
      <div className={s.oneHistoryQuestionsDiv}>
        {h.questions.map((q: QuestionType) => {
          return (
            <div key={q.id + h.dateTime}>
              {q.question === "Изображение" ? (
                <img src={q.answer} alt="" />
              ) : (
                <>
                  {" "}
                  <div>Вопрос: {q.question}</div>
                  {q.questionType === QUESTION_OPTION_TYPE.options ||
                  q.questionType === QUESTION_OPTION_TYPE.semiOptional ? (
                    <>
                      {q.answer && q.options && (
                        <>{q.options.find((o) => o.id === q.answer)?.text}</>
                      )}
                    </>
                  ) : (
                    <h5>Ответ: {q.answer}</h5>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className={s.oneHistoryField}>{h.gptResponse}</div>
      <div className={s.oneHistoryField}>
        {h.isFavorite ? (
          <img
            alt=""
            src={favorityRedIcon}
            onClick={() => onRemoveFavoriteClickHandler(h.id)}
          />
        ) : (
          <img
            alt=""
            src={favorityWhiteEmptyIcon}
            onClick={() => onAddFavoriteClickHandler(h.id)}
          />
        )}
      </div>
    </div>
  );
};

export default OneHistorySection;
