import s from "./oneGptAnswer.module.css";
import OneAptAnswerButtons from "./buttons/gptAnswerbuttons";
import GptAnswerTextFields from "./textFields/gptAnswerTextFileds";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { GPTAnswerType } from "../../../../store/features/answer/answerTypes";
import LocalTextStat from "./localTextStat/localTeextStat";

type OneGptAnswerPropsType = {
  index: number;
  gptAnswer: GPTAnswerType;
};
const OneGptAnswer = (props: OneGptAnswerPropsType) => {
  const answersFullText: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersFullTextMode
  );
  const isThisTextFull: boolean = answersFullText.some(
    (el) => el === props.gptAnswer.answerId
  );
  const answersTextEditing: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersTextEditing
  );
  const isTextEditing: boolean = answersTextEditing.some(
    (el) => el === props.gptAnswer.answerId
  );

  const wrapperClass: string =
    isThisTextFull || isTextEditing
      ? s.oneGptAnswerWrapper + " " + s.gprAnswerWide
      : s.oneGptAnswerWrapper + " " + s.gprAnswerShort;

  return (
    <div className={wrapperClass}>
      <OneAptAnswerButtons
        answerId={props.gptAnswer.answerId}
        gptAnswer={props.gptAnswer}
      />

      <LocalTextStat
        answerId={props.gptAnswer.answerId}
        gptResponse={props.gptAnswer.gptResponse}
      />
      {props.gptAnswer.img_url && (
        <img className={s.image} src={props.gptAnswer.img_url} alt="" />
      )}
      <GptAnswerTextFields
        answerId={props.gptAnswer.answerId}
        gptAnswer={props.gptAnswer}
      />
    </div>
  );
};

export default OneGptAnswer;
