import s from "./gptAnswerbuttons.module.css";
// import emptyHeartIcon from "../../../../../assets/icons/gprResponse/heartIcon.svg";
// import redHeartIcon from "../../../../../assets/icons/gprResponse/favorite_icon_red.png";
// import downloadIcon from "../../../../../assets/icons/gprResponse/download_icon.png";
// import normalCopyIcon from "../../../../../assets/icons/gprResponse/copyIcon.svg";
// import eyeShowIcon from "../../../../../assets/icons/gprResponse/eyeIcon.svg";
// import deleteIcon from "../../../../../assets/icons/gprResponse/remove_icon.png";
// import pencilIcon from "../../../../../assets/icons/gprResponse/pencil_short_icon.svg";
import { 
  RootState, 
  // useAppDispatch 
} from "../../../../../store/store";
// import {
//   // addFullTextAnswerAC,
//   addTextEditingAC,
//   changeGptAnswerResponseTextAC,
//   removeFullTextAnswerAC,
//   removeGptAnswerAC,
//   removeTextEditingAC,
// } from "../../../../../store/features/answerSlice";
import { useSelector } from "react-redux";
// import copy from "copy-to-clipboard";
// import { toast } from "react-toastify";
import {
  ChangeAnswerEditingTextType,
  // ChangeOneAnswerResponseType,
  GPTAnswerType,
  GptHistoryType,
} from "../../../../../store/features/answer/answerTypes";
// import {
//   DeleteGptHistoryFavoriteThunkRequestType,
//   PostGptHistoryFavoriteThunkRequestType,
//   deleteGptHistoryFavoriteThunk,
//   postGptHistoryFavoriteThunk,
// } from "../../../../../store/features/answerThunk";
import { CopyAnswerButton } from "./btn-copy";
import { ShowAnswerButton } from "./btn-show";
import { DownloadAnswerButton } from "./btn-download";
import { EditAnswerButton } from "./btn-edit";
import { AddFavoriteAnswerButton } from "./btn-favorite";
import { DeleteAnswerButton } from "./btn-delete";

// import Markdown from 'react-markdown';
// import reactElementToJSXString from 'react-element-to-jsx-string';
// import remarkGfm from 'remark-gfm'

type OneAptAnswerButtonsPropsType = {
  answerId: string;
  gptAnswer: GPTAnswerType;
};

const OneAptAnswerButtons = (props: OneAptAnswerButtonsPropsType) => {
  // const dispatch = useAppDispatch();

  const answersFullTextMode: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersFullTextMode
  );
  const isThisTextFull: boolean = answersFullTextMode.some(
    (el) => el === props.gptAnswer.answerId
  );

  const answersTextEditing: Array<string> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.answersTextEditing
  );
  const isTextEditing: boolean = answersTextEditing.some(
    (el) => el === props.gptAnswer.answerId
  );

  const newEditedTextArray: Array<ChangeAnswerEditingTextType> = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.editingTextArray
  );
  const newEditedText: string | undefined =
    newEditedTextArray.find((el) => el.answerId === props.answerId)?.newText

  // const eyeIconClass: string = isThisTextFull
  //   ? s.buttonSection + " " + s.buttonSectionSelected
  //   : s.buttonSection;
  // const pencilIconClass: string = isTextEditing
  //   ? s.buttonSection + " " + s.buttonSectionSelected
  //   : s.buttonSection;
  // const ableDisableBtnClass: string = isTextEditing
  //   ? s.buttonSection + " " + s.buttonSection_disabled
  //   : s.buttonSection;

  const gptHistory: Array<GptHistoryType> = useSelector(
    (state: RootState) => state.answer.gptHistory
  );
  const isTheAnswerFavorite: boolean = gptHistory.some(
    (el) => el.id === props.answerId && el.isFavorite
  );
  // const heartIcon: any = isTheAnswerFavorite ? redHeartIcon : emptyHeartIcon;

  // Click handlers
  // const onEyeIconClickHandler = () => {
  //   if (isTextEditing) {
  //     const isConfirm = window.confirm("Сохранить изменения?");
  //     if (isConfirm) {
  //       onSaveClickHandler();
  //     }
  //     dispatch(removeFullTextAnswerAC(props.gptAnswer.answerId));
  //     dispatch(removeTextEditingAC(props.gptAnswer.answerId));
  //   }

  //   if (isThisTextFull) {
  //     dispatch(removeFullTextAnswerAC(props.gptAnswer.answerId));
  //     dispatch(removeTextEditingAC(props.gptAnswer.answerId));
  //   } else {
  //     dispatch(addFullTextAnswerAC(props.gptAnswer.answerId));
  //   }
  // };
  // const onPencilClickHandler = () => {
  //   if (isThisTextFull) {
  //     dispatch(removeFullTextAnswerAC(props.gptAnswer.answerId));
  //   }

  //   if (isTextEditing) {
  //     const isConfirm = window.confirm("Сохранить изменения?");
  //     if (isConfirm) {
  //       const data: ChangeOneAnswerResponseType = {
  //         answerId: props.gptAnswer.answerId,
  //         newAnswerText: newEditedText || "",
  //       };
  //       dispatch(changeGptAnswerResponseTextAC(data));
  //     }
  //     dispatch(removeTextEditingAC(props.gptAnswer.answerId));
  //   } else {
  //     dispatch(addTextEditingAC(props.gptAnswer.answerId));
  //   }
  // };
  
  // const onCopyClickHandler = () => {
  //   if (isTextEditing) {
  //     toast.error("Сначала надо завершить редактирование");
  //   } else {
      
  //     const elem = document.getElementById(props.answerId)
  //     if (elem) {
  //       const txt = elem.innerHTML
  //       copy(txt, {format: 'text/html'});
  //     }

  //     // if (newEditedText) {
  //     //   alert('1')
  //     //   const textToJSX = <Markdown remarkPlugins={[remarkGfm]}>{newEditedText}</Markdown>
  //     //   const mdText:string = reactElementToJSXString(textToJSX)
        
  //     //   copy(mdText, {format: 'text/html'});
  //     // } else {
  //     //   alert('2')
  //     //   const mdText = <Markdown>{props.gptAnswer.gptResponse}</Markdown>
  //     //   copy(mdText.toString(), {format: 'text/html'});
  //     // }
  //     toast.info("Текст скопирован");
  //   }
  // };

  // const onDownloadClickHandler = () => {
  //   if (isTextEditing) {
  //     toast.error("Сначала надо завершить редактирование");
  //   } else {
  //     const saveFileName: string =
  //       "Пиарошная_" + props.gptAnswer.responseTitle + ".txt";
  //     const FileSaver = require("file-saver");
      
      
  //     const blob = newEditedText
  //       ? new Blob([newEditedText], {
  //         type: "text/plain;charset=utf-8",
  //       })
  //       : new Blob([props.gptAnswer.gptResponse], {
  //         type: "text/plain;charset=utf-8",
  //       })

  //     // if (newEditedText) {
  //     //   copy(newEditedText)
  //     // } else {
  //     //   copy(props.gptAnswer.gptResponse);
  //     // }
  //     FileSaver.saveAs(blob, saveFileName);
  //   }
  // };

  // const onSaveClickHandler = () => {
  //   const data: ChangeOneAnswerResponseType = {
  //     answerId: props.gptAnswer.answerId,
  //     newAnswerText: props.gptAnswer.gptResponse,
  //   };
  //   dispatch(changeGptAnswerResponseTextAC(data));
  //   toast.info("Редактированный текст сохранен");
  // };

  // const onDeleteClickHandler = () => {
  //   const answerAboutDeleting = window.confirm("Точно хотите удалить ответ?");
  //   if (answerAboutDeleting) {
  //     dispatch(removeGptAnswerAC(props.gptAnswer.answerId));
  //   }
  // };

  // const onFavoriteClickHandler = () => {
  //   const data:
  //     | PostGptHistoryFavoriteThunkRequestType
  //     | DeleteGptHistoryFavoriteThunkRequestType = { id: props.answerId };
  //   if (isTheAnswerFavorite) {
  //     dispatch(deleteGptHistoryFavoriteThunk(data));
  //   } else {
  //     dispatch(postGptHistoryFavoriteThunk(data));
  //   }
  // };

  return (
    <>
      <div className={s.columnsWide}>
        {/* <div
          className={eyeIconClass}
          onClick={onEyeIconClickHandler}
          data-title="Посмотреть"
        >
          <img alt="" src={eyeShowIcon} />
        </div> */}
        <ShowAnswerButton 
          answerId={props.gptAnswer.answerId} 
          gptResponse={props.gptAnswer.gptResponse} 
          isTextEditing={isTextEditing} 
          isThisTextFull={isThisTextFull} 
        />

        {/* <div
          className={pencilIconClass}
          onClick={onPencilClickHandler}
          data-title="Редактировать"
        >
          <img alt="" src={pencilIcon} />
        </div> */}
        <EditAnswerButton 
          isTextEditing={isTextEditing} 
          isThisTextFull={isThisTextFull} 
          answerId={props.gptAnswer.answerId} 
          newEditedText={newEditedText} 
        />

        {/* <div
          className={s.buttonSection}
          onClick={onFavoriteClickHandler}
          data-title="Добавить в избранное"
        >
          <img alt="" src={heartIcon} />
        </div> */}
        <AddFavoriteAnswerButton isTheAnswerFavorite={isTheAnswerFavorite} answerId={props.answerId} />

        {/* <div
          className={ableDisableBtnClass}
          onClick={onCopyClickHandler}
          data-title="Скопировать"
        >
          <img alt="" src={normalCopyIcon} />
        </div> */}
        <CopyAnswerButton 
          answerId={props.answerId} 
          isTextEditing={isTextEditing} 
          newEditedText={newEditedText}
          gptResponse={props.gptAnswer.gptResponse}
        />

        {/* <div
          className={ableDisableBtnClass}
          onClick={onDownloadClickHandler}
          data-title="Скачать"
        >
          <img alt="" src={downloadIcon} />
        </div> */}
        <DownloadAnswerButton 
          isTextEditing={isTextEditing}
          responseTitle={props.gptAnswer.responseTitle} 
          newEditedText={newEditedText} 
          gptResponse={props.gptAnswer.gptResponse}
        />

        {/* {
                <div className={s.buttonSection} onClick={onSaveClickHandler}>
                <img alt="" src={saveIcon}/>
            </div>
            } */}

        <div className={s.longDiv}></div>

        {/* <div
          className={s.buttonSection}
          onClick={onDeleteClickHandler}
          data-title="Удалить"
        >
          <img alt="" src={deleteIcon} />
        </div> */}
        <DeleteAnswerButton answerId={props.gptAnswer.answerId} />
      </div>
    </>
  );
};

export default OneAptAnswerButtons;
