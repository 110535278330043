import { AnswerInitContentType } from "../features/answer/answerTypes";
import { ParametrSliceType } from "../features/parametrTypes";
import { ProductInitContentType } from "../features/productTypes";
import { QuestionSliceInitType } from "../features/questionTypes";
import { HISTORY_SEARCH_FAV_VALUES } from "./consts";

export const questionInitContent: QuestionSliceInitType = {
  categories: [],
  questions: [],
  templates: [],
  models: [],
  templateVars: {
    showTemplateQuestionsList: [],
  },
  loadingVars: {
    isCategoryLoading: false,
    isTemplatesLoading: false,
    isQuestionsLoading: false,
  },
  QuestionSliceVars: {
    currentGptType: null,
    isShowQuestionMobileVerion: true
  }
};

export const ParametrInitContent: ParametrSliceType = {
  isSideMenuLarge: true,
  // theme: DARK_THEME,
  contentBlockQuestionsShow: true,
};

export const answerInitContent: AnswerInitContentType = {
  answers: [],
  gptAnswerVars: {
    answersFullTextMode: [],
    answersTextEditing: [],
    newGptAnswers: [],
    editingTextArray: [],
    isGptAccessDenied: true,
    isLimitationOn: false,
  },
  loadingVars: {
    isGptAnswerLoading: false,
    isHistoryLoading: false,
    whichCategoryGptAnswerLoading: [],
  },
  gptHistory: [],
  gptHistorySearchVars: {
    userAnswerTextSearch: "",
    gptTextSearch: "",
    dateStartSearch: null,
    dateEndSearch: null,
    favoriteSearch: HISTORY_SEARCH_FAV_VALUES.all,
  },
};

export const productInitContent: ProductInitContentType = {
  products: [],
  loadingVars: {
    isProductLoading: false,
    isPayProductLoading: false,
    isPromoCheckLoading: false,
  },
  promoCode: "",
  categoriesSelection: []
};
