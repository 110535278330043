import { AxiosResponse } from "axios";
import { instance } from "../instance";

export type ChatGptSendThunk_ApiType = {
    question_answer: Array<{
      question_id: string;
      answer: string | null;
    }>;
    category_id: {
      model_gpt_id: string;
      categoryId: string;
    };
};

export const chatGptResponseApi = {
    getGptResponseV2: (
      data: ChatGptSendThunk_ApiType
    ): Promise<AxiosResponse> => {
      const newData = {
        ...data, 
        category_id: {
          ...data.category_id,
          model_gpt_id: data.category_id.model_gpt_id.length > 0 ? data.category_id.model_gpt_id : null
        }
      }

      return instance.post("question/response", newData);
    },
  };
  