import { useSelector } from "react-redux";
import { changeIsLimitationOnAC } from "../../../../store/features/answer/answerSlice";
import { RootState, useAppDispatch } from "../../../../store/store";
import s from "./isLimitation.module.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../../common/router/paths";
import { Button } from "../../../../components/button/button";
import { ButtonVariant } from "../../../../store/storeConst/enums";

const IsLimitationNotice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const show: boolean = useSelector(
    (state: RootState) => state.answer.gptAnswerVars.isLimitationOn
  );

  const onCloseClickHandler = () => dispatch(changeIsLimitationOnAC(false));
  const onGoToPruductsClickHandler = () => {
    dispatch(changeIsLimitationOnAC(false));
    navigate(Paths.tariffs);
  };

  return (
    <>
      {show && (
        <div className={s.isLimitation_layout}>
          <div className={s.isLimitation_wrapper}>
            <h3>Приносим свои извинения!</h3>
            <label>
              Генерация текстов доступна только при действующем тарифе!
            </label>
            <Button onClick={onGoToPruductsClickHandler}>
              Посмотреть тарифы
            </Button>
            <Button
              variant={ButtonVariant.Secondary}
              onClick={onCloseClickHandler}
            >
              Закрыть
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default IsLimitationNotice;
