import { useSelector } from "react-redux";
import s from "./gptHistory.module.css";
import { RootState, useAppDispatch } from "../../store/store";
import {
  GptHistorySearchVarsType,
  GptHistoryType,
} from "../../store/features/answer/answerTypes";
import OneHistorySection from "./oneHistorySection/oneHistorySection";
import { useEffect } from "react";
import { getGptHistoryThunk } from "../../store/features/answer/answer-history-thunk";
import { CategoryType } from "../../store/features/questionTypes";
import { useNavigate, useParams } from "react-router-dom";
import { Paths } from "../../common/router/paths";
import HistorySearch from "./gptHistorySearchMenu/historySearch";
import { HISTORY_SEARCH_FAV_VALUES } from "../../store/storeConst/consts";
import { Loader } from "../../components/loader/loader";
import { Button } from "../../components/button/button";

const GptHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { categoryId } = params || "";

  const isLoading: boolean = useSelector(
    (state: RootState) => state.answer.loadingVars.isHistoryLoading
  );

  const gptHistorySearchVars: GptHistorySearchVarsType = useSelector(
    (state: RootState) => state.answer.gptHistorySearchVars
  );
  const gptHistory0: Array<GptHistoryType> = [
    ...useSelector((state: RootState) => state.answer.gptHistory),
  ];
  const gptHistory: Array<GptHistoryType> =
    gptHistory0 && gptHistory0.length > 0
      ? gptHistory0
          .filter((el) =>
            el.gptResponse.includes(gptHistorySearchVars.gptTextSearch)
          )
          .filter((el) =>
            el.questions.some((q) =>
              q.answer?.includes(gptHistorySearchVars.userAnswerTextSearch)
            )
          )
          .filter((el) => {
            return gptHistorySearchVars.dateStartSearch
              ? el.dateTime > gptHistorySearchVars.dateStartSearch
              : true;
          })
          .filter((el) => {
            return gptHistorySearchVars.dateEndSearch
              ? el.dateTime < gptHistorySearchVars.dateEndSearch
              : true;
          })
          .filter((el: GptHistoryType) => {
            return (
              gptHistorySearchVars.favoriteSearch ===
                HISTORY_SEARCH_FAV_VALUES.all ||
              (gptHistorySearchVars.favoriteSearch ===
                HISTORY_SEARCH_FAV_VALUES.fav &&
                el.isFavorite) ||
              (gptHistorySearchVars.favoriteSearch ===
                HISTORY_SEARCH_FAV_VALUES.notFav &&
                !el.isFavorite)
            );
          })
          .sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1))
      : [];

  const categories: Array<CategoryType> = useSelector(
    (state: RootState) => state.question.categories
  );
  const categoryTitle: string =
    categories.find((cat) => cat.id === categoryId)?.title || "";

  const onBackButtonClickHandler = () => {
    if (categoryId && categoryId.length > 0) {
      navigate(Paths.category + "/" + categoryId);
    }
  };

  useEffect(() => {
    dispatch(getGptHistoryThunk({ categoryId: categoryId || "" }));
  }, []);

  return (
    <div className={s.historyWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={s.back_btn_div}>
            <Button onClick={onBackButtonClickHandler}>Вернуться назад</Button>
          </div>
          История для раздела
          <h3>{categoryTitle}</h3>
          <HistorySearch />
          {gptHistory.length === 0 && (
            <h3>Пока нет запросов в данном разделе.</h3>
          )}
          {gptHistory.map((h: GptHistoryType, index: number) => {
            return <OneHistorySection index={index} h={h} key={h.id} />;
          })}
        </>
      )}
    </div>
  );
};

export default GptHistory;
