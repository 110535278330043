import { toast } from "react-toastify";
import { addFullTextAnswerAC, changeGptAnswerResponseTextAC, removeFullTextAnswerAC, removeTextEditingAC } from "../../../../../store/features/answer/answerSlice";
import { ChangeOneAnswerResponseType } from "../../../../../store/features/answer/answerTypes";
import { useAppDispatch } from "../../../../../store/store";

// import eyeShowIcon from "../../../../../assets/icons/gprResponse/eyeIcon.svg";
import eyeShowIcon from "../../../../../assets/icons/gprResponse/eyeIcon-2.svg";

import s from "./gptAnswerbuttons.module.css";

type PropsType = {
    answerId: string
    gptResponse: string
    isTextEditing: boolean
    isThisTextFull: boolean
}

export const ShowAnswerButton = ({isTextEditing, gptResponse, answerId, isThisTextFull}: PropsType) => {
    const dispatch = useAppDispatch();

    const eyeIconClass: string = isThisTextFull
    ? s.buttonSection + " " + s.buttonSectionSelected
    : s.buttonSection;

    const onSaveClickHandler = () => {
        const data: ChangeOneAnswerResponseType = {
          answerId: answerId,
          newAnswerText: gptResponse,
        };
        dispatch(changeGptAnswerResponseTextAC(data));
        toast.info("Редактированный текст сохранен");
      };

    const onEyeIconClickHandler = () => {
        if (isTextEditing) {
          const isConfirm = window.confirm("Сохранить изменения?");
          if (isConfirm) {
            onSaveClickHandler();
          }
          dispatch(removeFullTextAnswerAC(answerId));
          dispatch(removeTextEditingAC(answerId));
        }
    
        if (isThisTextFull) {
          dispatch(removeFullTextAnswerAC(answerId));
          dispatch(removeTextEditingAC(answerId));
        } else {
          dispatch(addFullTextAnswerAC(answerId));
        }
    }

    return (
        <div
          className={eyeIconClass}
          onClick={onEyeIconClickHandler}
          data-title="Посмотреть"
        >
          <img alt="" src={eyeShowIcon} />
        </div>
    )
}