import { toast } from "react-toastify";
import { visionAPI } from "../../../common/api/vision-gpt-api/vision-GPT";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { profileActions } from "../profile/profile-slice";
import { ChangingOneQuestionType } from "../questionTypes";
import { changeIsLimitationOnAC, removeWhichCategoryGptLoadingAC } from "./answerSlice";
import { standartApiErrorMessage } from "../../../common/api/errorCodes";

export type ResponseVisionType = {
    content: string;
    img_url: string;
    status: string;
};
type sendVisionGPTThunkType = ResponseVisionType & { category_id: string };
export type RequestVisionType = {
    image: File;
    request_data: RequestVisionDataType;
};

export type RequestVisionDataType = {
    category_id: string;
    answers: ChangingOneQuestionType[];
};
  
export const sendVisionGPTThunk = createAppAsyncThunk<
  sendVisionGPTThunkType,
  RequestVisionType
>("answer/sendVisionGPT", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  const formData = new FormData();
  const request_data = JSON.stringify(arg.request_data);
  formData.append("image", arg.image);
  formData.append("request_data", request_data);

  try {
    const data = await visionAPI.send(formData);
    dispatch(removeWhichCategoryGptLoadingAC(arg.request_data.category_id));
    dispatch(profileActions.setRemainingUses(data.data.content.length));
    return { ...data.data, category_id: arg.request_data.category_id };
  } catch (err: any) {
    dispatch(removeWhichCategoryGptLoadingAC(arg.request_data.category_id));
    if (err.response.data.message === "Access denied") {
      toast.error(
        "Доступ к данной категории возможен только с оплаченным тарифом"
      );
      dispatch(changeIsLimitationOnAC(true));
    } else {
      standartApiErrorMessage({code: err.response.status, message: err.response.data.message})
    }
    return rejectWithValue(null);
  }
});
