import { useSelector } from "react-redux";
import {
  CategoryTypeGpt,
  // ChangingOneQuestionType,
  QuestionType,
} from "../../../store/features/questionTypes";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import OneQuestion from "./oneQuestion/oneQuestion";
import showIcon from "../../../assets/icons/contentPage/folding_show_icon.svg";
import hideIcon from "../../../assets/icons/contentPage/folding_hide_icon.svg";
import QuestionsButtons from "./questionsButtons/questionsButtons";
import { changeIsContentQuestionsShow } from "../../../store/features/parametrSlice";
import {
  ResizeType,
  useResize,
} from "../../../common/hooks/use-resize/useResize";
import { Loader } from "../../../components/loader/loader";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UploadImage } from "../../../components/upload-image/upload-image";
import { appActions } from "../../../store/features/app/app-slice";
import { appSelectors } from "../../../store/features/app/app-selectors";
import { ModelSelectionQuestionsArea } from "./model-selection/model-selection-area";
import { QuestionsBlockMiniHeader } from "../../../components/questions-mini-header/questions-mini-header";

import s from "./questions.module.css";
import { UploadDoc } from "../../../components/upload-image/upload-doc";

type QuestionsPropsType = {
  // type_gpt: CategoryTypeGpt;
  mobileSwitch?: () => void;
};
// const Questions = ({ mobileSwitch, type_gpt }: QuestionsPropsType) => {
const Questions = () => {
  const dispatch = useAppDispatch();
  const devWidth: ResizeType = useResize();
  const isQuestionsShown = useAppSelector(
    (state) => state.parametr.contentBlockQuestionsShow
  );
  const gptType = useAppSelector((state) => state.question.QuestionSliceVars.currentGptType)

  const { tourActive } = useAppSelector(appSelectors.getOnboarding());

  const [imageForVision, setImage] = useState<File | null>(null);

  const showHideIcon: any =
    isQuestionsShown && devWidth.width > 620 ? hideIcon : showIcon;
  const questions = useAppSelector((state) => state.question.questions);
  const models = useAppSelector((state) => state.question.models);
  const [selectModel, setSelectModel] = useState(
    models.length > 0 ? models[0].value : ""
  );
  
  useEffect(() => {
    models.length > 0 && setSelectModel(models[0].value);
  }, [models]);

  const params = useParams();
  const currentCategoryId = params.categoryId || "";

  const isQuestionsFulfilled: boolean = questions && questions.length > 0;
  const isQuestionsLoading: boolean = useSelector(
    (state: RootState) => state.question.loadingVars.isQuestionsLoading
  );

  const qeustionsPopupMsg: Array<string> = [
    'В этом блоке нужно предоставить нейросети информацию о будущем тексте. Как говорят журналисты и копирайтеры, "дать фактуру". И это не сложно!',
    'Просто отвечайте на наши наводящие вопросы, и чем больше информации вы дадите, тем более качественным и "кастомизированным" будет результат!'
  ]
  
  const onShowHideClickHandler = () => {
    dispatch(changeIsContentQuestionsShow(!isQuestionsShown));
  };

  useEffect(() => {
    if (isQuestionsShown && tourActive && !isQuestionsLoading) {
      // Запускаем онбординг если он запущен при переходе на страницу. {
      dispatch(appActions.setOnboarding({ run: true, stepIndex: 13 }));
    }
  }, [isQuestionsShown, tourActive, isQuestionsLoading, dispatch]);
  return (
    <div className={s.contentWrapper}>
      {isQuestionsLoading ? (
        <Loader position={"container"} />
      ) : (
        <>
          <div className={s.titleDiv}>
            {isQuestionsShown && <h3>Заполнение данных</h3>}

            {devWidth.width > 620 && (
              <div className={s.showHideDiv}>
                <img
                  alt=""
                  src={showHideIcon}
                  onClick={onShowHideClickHandler}
                  className={s.showHideImg}
                />
              </div>
            )}
          </div>
          {isQuestionsFulfilled && isQuestionsShown ? (
            <>

              <ModelSelectionQuestionsArea 
                selectModel={selectModel} 
                models={models} 
                setSelectModel={(newModel: string) => setSelectModel(newModel)}
              />
              
              <div id={"questionsDiv"} className={s.questionsDiv}>

                <QuestionsBlockMiniHeader text={"О чем пишем?"} popupText={qeustionsPopupMsg} />

                {questions.map((q: QuestionType) => {
                  return <OneQuestion key={q.question} question={q} />;
                })}
                {/* {type_gpt === "gpt_vision" && ( */}
                {gptType === "gpt_vision" && (
                  <UploadImage setImage={setImage} />
                )}
                {/* {type_gpt === "gpt_files" && ( */}
                {gptType === "gpt_files" && (
                  <UploadDoc setImage={setImage} />
                )}
                {/* <Button
                  id={"generateTextButton"}
                  className={s.button}
                  onClick={onSendToGPRClickHandler}
                  img={send}
                  disabled={isThisCatAnswerLoading}
                >
                  Генерировать текст
                </Button> */}
              </div>
              <QuestionsButtons 
                // type_gpt={type_gpt} 
                currentCategoryId={currentCategoryId} 
                imageForVision={imageForVision} 
                selectModel={selectModel} 
              />
            </>
          ) : (
            <div className={s.under_construction_div}>
              {isQuestionsShown && (
                <>
                  <h3>Данный раздел </h3>
                  <h3>в стадии разработки...</h3>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Questions;
