import { ChangeEvent, DragEvent, useRef, useState } from "react";
import AddImage from "../../assets/icons/add-Image";
// import AddImage from "../../assets/icons/contentPage/add-doc-pdf.svg"
import { Typography } from "../typography/Typography";
import { TypographyVariant } from "../../store/storeConst/enums";
import clsx from "clsx";

import docIcon from "../../assets/icons/gprResponse/word-icon.png"
import pdfIcon from "../../assets/icons/gprResponse/pdf-icon.png"

import s from "./upload-image.module.scss";

type PropsType = {
  setImage: (docFile: File) => void;
};

export const UploadDoc = ({ setImage }: PropsType) => {
  const ref = useRef<HTMLInputElement>(null);
  const [drag, setDrag] = useState(false);
  const [previewFile, setPreviewFile] = useState<null | string>(null);
  const [fileName, setFileName] = useState<string | null>(null)
  
  const reader = new FileReader();
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name)
    }
    reader.onloadend = () => {
      setPreviewFile(reader.result as string);
    };
    file && reader.readAsDataURL(file);
    setImage(file!);
  };
  const dragHandlerStart = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragHandlerLeave = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDropHandler = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    reader.onloadend = () => {
      setPreviewFile(reader.result as string);
    };
    file && reader.readAsDataURL(file);
    setImage(file);
    setDrag(false);
  };
  return (
    <div
      onDragStart={dragHandlerStart}
      onDragLeave={dragHandlerLeave}
      onDragOver={dragHandlerStart}
      onDrop={onDropHandler}
      className={clsx(
        drag && s.drag,
        s.uploader,
        previewFile && s.previewImage
      )}
      onClick={() => ref.current?.click()}
    >
      {previewFile ? (
        <div className={s.file_name_div}>
          {fileName && fileName.slice(fileName.length - 4, fileName.length) === '.pdf' &&
            <img alt={"file"} src={pdfIcon} />
          }
          {fileName && (
              fileName.slice(fileName.length - 4, fileName.length) === '.doc' ||
              fileName.slice(fileName.length - 4, fileName.length) === 'docx'
            ) &&
            <img alt={"file"} src={docIcon} />
          }
          <div>
            {fileName}
          </div>
        </div>
      ) : (
        <>
          <AddImage />
          <Typography variant={TypographyVariant.Body2}>
            {drag
              ? "Отпустите файл чтобы загрузить его!"
              : "Выберите PDF/DOC/DOCX или перетащите его в это поле."}
          </Typography>
        </>
      )}

      <input
        className={s.fileInput}
        ref={ref}
        accept={".pdf, .doc, .docx"}
        onChange={onChangeHandler}
        type={"file"}
      />
    </div>
  );
};
