import { toast } from "react-toastify";

import downloadIcon from "../../../../../assets/icons/gprResponse/download_icon.png";

import s from "./gptAnswerbuttons.module.css";

type PropsType = {
    isTextEditing: boolean
    responseTitle: string
    newEditedText: string | undefined
    gptResponse: string
}

export const DownloadAnswerButton = ({isTextEditing, responseTitle, newEditedText, gptResponse}: PropsType) => {

    const ableDisableBtnClass: string = isTextEditing
    ? s.buttonSection + " " + s.buttonSection_disabled
    : s.buttonSection;

    const onDownloadClickHandler = () => {
        if (isTextEditing) {
          toast.error("Сначала надо завершить редактирование");
        } else {
          const saveFileName: string =
            "Пиарошная_" + responseTitle + ".txt";
          const FileSaver = require("file-saver");
          
          
          const blob = newEditedText
            ? new Blob([newEditedText], {
              type: "text/plain;charset=utf-8",
            })
            : new Blob([gptResponse], {
              type: "text/plain;charset=utf-8",
            })
    
          FileSaver.saveAs(blob, saveFileName);
        }
      }

      return (
        <div
          className={ableDisableBtnClass}
          onClick={onDownloadClickHandler}
          data-title="Скачать"
        >
          <img alt="" src={downloadIcon} className={s.buttonSection_img} />
        </div>
      )
}