export type CurrentModelsIdsType = {
    seriousModelId_1: string,
    seriousModelId_2: string,
    creativeModelId: string,
    rusModelId: string,
    balancedModelId_1: string
    balancedModelId_2: string
}

export const currentModelsIds:CurrentModelsIdsType = {
    seriousModelId_1: "02a80bec-d589-4966-8f61-4ec3c30fbd9a",
    seriousModelId_2: "1aeb52d6-d8fb-4760-89a4-1157d931c531",
    creativeModelId: "cb7194e2-edf1-479b-bc10-ef5b4b40562e",
    rusModelId: "4218c187-0a0a-442c-bee0-e1c2dca5632b",
    // balancedModelId_1: "b3377414-0511-499a-893b-6bb47e2d5f56",
    balancedModelId_1: "d5448ffc-664c-49bd-857a-bf2a0a676853",
    balancedModelId_2: "e935cc32-5af5-4c9b-aa71-645260d764b7",
}