import { useSelector } from "react-redux";
import { GPTAnswerType } from "../../../store/features/answer/answerTypes";
import { RootState } from "../../../store/store";
import OneGptAnswer from "./oneGptAnswer/oneGptAnswer";
import ResponseWaiting from "./responseWaiting/responseWaiting";
import { useNavigate, useParams } from "react-router-dom";
import { RESPONSE_WAITING_DIV_ID } from "../../../store/storeConst/consts";
import { Paths } from "../../../common/router/paths";
import requestHistoryIcon from "../../../assets/icons/contentPage/requestHistoryIcon.svg";
import IsLimitationNotice from "./isLimitationNotice/isLimitation";
import { Loader } from "../../../components/loader/loader";
import { Button } from "../../../components/button/button";
import { CategoryTypeGpt } from "../../../store/features/questionTypes";
import { QuestionsBlockMiniHeader } from "../../../components/questions-mini-header/questions-mini-header";

import s from "./answer.module.scss";

const AnswerGPT = ({ type_gpt }: { type_gpt: CategoryTypeGpt }) => {
  const params = useParams();
  const currentCategoryId = params.categoryId || "";
  const navigate = useNavigate();

  const gptAnswers: Array<GPTAnswerType> = useSelector(
    (state: RootState) => state.answer.answers
  );
  const whichCategoryGptAnswerLoading: Array<string> = useSelector(
    (state: RootState) => state.answer.loadingVars.whichCategoryGptAnswerLoading
  );
  const isThisCatAnswerLoading: boolean = //isGptAnswerLoading &&
    whichCategoryGptAnswerLoading.some((el) => currentCategoryId === el);

  const onShowHistoryClickHandler = () => {
    navigate(Paths.gptHistory + "/" + currentCategoryId);
  };

  return (
    <div className={s.answerWrapper}>
      <IsLimitationNotice />

      <div className={s.answerTitleDiv} id={RESPONSE_WAITING_DIV_ID}>
        <h3>Поле ответа</h3>
      </div>

      <div className={s.answersDiv}>

        <QuestionsBlockMiniHeader text={gptAnswers.length > 1 ? "Ответы «Пиарошной»:" : "Ответ «Пиарошной»"} popupText={[]} notShowPopup={true} />
        {/* {"Ответ «Пиарошной»"} popupText={[]} /> */}
        <div className={s.gptResponseTextTitle}>
          {/* {gptAnswers.length > 1 ? <>Текст ответов:</> : <>Текст ответа:</>} */}
          <div />
          <Button
            onClick={onShowHistoryClickHandler}
            img={requestHistoryIcon}
            disabled={isThisCatAnswerLoading}
          >
            История запросов
          </Button>
        </div>
        
        {gptAnswers.length === 0 && !isThisCatAnswerLoading && (
          <div className={s.gptResponseText}>
            Пока нет ни одного ответа, поскольку Вы ничего не запрашивали
          </div>
        )}

        {isThisCatAnswerLoading && (
          <>
            <Loader position={"container"} />
            <ResponseWaiting />
          </>
        )}

        {gptAnswers.length > 0 &&
          gptAnswers
            .filter((a: GPTAnswerType) => a.categoryId === currentCategoryId)
            .map((answer: GPTAnswerType, index: number) => {
              return (
                <OneGptAnswer
                  index={index}
                  key={answer.answerId}
                  gptAnswer={answer}
                />
              );
            })}
      </div>
    </div>
  );
};

export default AnswerGPT;
