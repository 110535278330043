import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { useState } from "react";
import clsx from "clsx";

import normalCopyIcon from "../../../../../assets/icons/gprResponse/copyIcon_2.svg";
import arrowDown from "../../../../../assets/icons/gprResponse/arrow_down.svg"

import s from "./gptAnswerbuttons.module.css";

type PropsType = {
    answerId: string
    isTextEditing: boolean
    newEditedText: string | undefined
    gptResponse: string
}

const CopyImage = ({isOpen}: {isOpen: boolean}) => {
    return (
        <>
            <img alt="" src={normalCopyIcon} className={s.buttonSection_img}/>
            <img alt="" src={arrowDown} className={isOpen ? clsx(s.rotate_img, s.buttonSection_img) : s.buttonSection_img}/>
        </>
    )
}

export const CopyAnswerButton = ({answerId, isTextEditing, newEditedText, gptResponse}: PropsType) => {

    const [isSnipperShown, setIsSnippetShown] = useState<boolean>(false)

    const ableDisableBtnClass: string = clsx(
        s.buttonSection,
        s.copy_img_div,
        isTextEditing && s.buttonSection_disabled
    )

    const copyAsHtml = () => {
        const elem = document.getElementById(answerId)
        if (elem) {
            const txt = elem.innerHTML
            copy(txt, {format: 'text/html'});
            toast.info("Текст скопирован для письма / документа office");
        } else {
            toast.error("Ошибка копирования")
        }
        setIsSnippetShown(false)
    }
      const copyAsPlain = () => {
        if (newEditedText) {
            copy(newEditedText)
        } else {
            copy(gptResponse)
        }
        toast.info("Текст скопирован для мессенджера");
        setIsSnippetShown(false)
      }

      const onCopyAreaClick = () => {
        if (isTextEditing) {
            toast.error("Сначала надо завершить редактирование текста ответа")
        } else {
            setIsSnippetShown(!isSnipperShown)
        }
      }

      return (
        <>
            <div
                className={ableDisableBtnClass}
                onClick={onCopyAreaClick}
                data-title="Скопировать"
            >
                <CopyImage isOpen={isSnipperShown} />

                {isSnipperShown &&
                    <div className={s.snippet_class}>
                        <div className={clsx(s.copy_label_text)}>
                            Скопировать текст:
                        </div>
                        <div 
                            onClick={copyAsHtml} 
                            className={s.snippet_elem_class}
                        >
                            Для письма / документа office
                        </div>
                        <div 
                            onClick={copyAsPlain} 
                            className={s.snippet_elem_class}
                        >
                            Для мессенджеров
                        </div>
                    </div>
                }
                
            </div>

                        
        </>
      )
}