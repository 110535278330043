import { 
  deleteGptHistoryFavoriteThunk, 
  DeleteGptHistoryFavoriteThunkRequestType, 
  postGptHistoryFavoriteThunk, 
  PostGptHistoryFavoriteThunkRequestType,
} from "../../../../../store/features/answer/answer-history-thunk";
import { useAppDispatch } from "../../../../../store/store";

// import emptyHeartIcon from "../../../../../assets/icons/gprResponse/heartIcon.svg";
// import redHeartIcon from "../../../../../assets/icons/gprResponse/favorite_icon_red.png";
// import heardIcon from "../../../../../assets/icons/gprResponse/heartIcon_2.svg"

import s from "./gptAnswerbuttons.module.css";

type PropsType = {
    isTheAnswerFavorite: boolean
    answerId: string
}

const HeartIconImage = ({isFavorite}: {isFavorite: boolean}) => {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      // fill="none" 
      fill={isFavorite ? "red" : "none"} 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M11.9936 6.29639C10.3275 4.33044 7.54914 3.8016 5.46159 5.60183C3.37405 7.40205 3.08016 10.4119 4.71951 12.5411C6.08253 14.3113 10.2075 18.0449 11.5594 19.2533C11.7107 19.3885 11.7863 19.4561 11.8745 19.4826C11.9515 19.5058 12.0358 19.5058 12.1128 19.4826C12.201 19.4561 12.2766 19.3885 12.4279 19.2533C13.7798 18.0449 17.9048 14.3113 19.2678 12.5411C20.9071 10.4119 20.6491 7.38312 18.5257 5.60183C16.4023 3.82054 13.6598 4.33044 11.9936 6.29639Z" 
        stroke={isFavorite ? "none" : "#292E32"} 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const AddFavoriteAnswerButton = ({isTheAnswerFavorite, answerId}: PropsType) => {

    const dispatch = useAppDispatch();

    // const heartIcon: any = isTheAnswerFavorite ? redHeartIcon : emptyHeartIcon;

    const onFavoriteClickHandler = () => {
        const data:
          | PostGptHistoryFavoriteThunkRequestType
          | DeleteGptHistoryFavoriteThunkRequestType = { id: answerId };
        if (isTheAnswerFavorite) {
          dispatch(deleteGptHistoryFavoriteThunk(data));
        } else {
          dispatch(postGptHistoryFavoriteThunk(data));
        }
    };

    
    return (
        <div
          className={s.buttonSection}
          onClick={onFavoriteClickHandler}
          data-title="Добавить в избранное"
        >
          {/* <img alt="" src={heartIcon} /> */}
          <HeartIconImage isFavorite={isTheAnswerFavorite} />
        </div>
    )
}