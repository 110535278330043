import { OptionType } from "../../../../components/select/Select"
import clsx from "clsx"
import { QuestionsBlockMiniHeader } from "../../../../components/questions-mini-header/questions-mini-header"
import { currentModelDescriptionFunction, ModelDescriptionType } from "./model-description-function"

import s from "./model-selection.module.css"

type PropsType = {
    selectModel: string
    models: Array<OptionType>
    setSelectModel: (newModel: string) => void
}

export const ModelSelectionQuestionsArea = ({selectModel, models, setSelectModel}: PropsType) => {

    const popupMsgText: Array<string> = [
        "Здесь меняется модель нейронки!",
        "На одной и той же фактуре - результаты будут принципиально разные!",
        "Мы вынесли в название ключевую суть «стиля» модели, но лучше попробуйте сами и выберете наиболее интересный вам вариант)",
    ]
    // const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

    const onModelClickHandler = (modelId: string) => {
        setSelectModel(modelId)
    }


    return (<>{models.length > 0 &&
        <div className={s.model_selection_wrapper} id={"model_selection_id"}>

            <QuestionsBlockMiniHeader text={"Как пишем?"} popupText={popupMsgText} />

            <div className={s.model_selection_grid_div}>
                {models.map(m => {
                    const currentModelDescription: ModelDescriptionType | undefined = currentModelDescriptionFunction(
                        {
                            modelId: m.value, 
                            isSelected: selectModel === m.value
                        }
                    )

                    return (
                        currentModelDescription ? (
                            <div 
                                key={m.value}
                                className={clsx(s.model_selection_field, m.value === selectModel ? s.selected : s.unselected)}
                                onClick={() => onModelClickHandler(m.value)}
                            >
                                {/* {currentModelImg} */}
                                {currentModelDescription.currentModelImage}
                                <div className={s.model_selection_label_1}>
                                    {m.title}
                                </div>
                                <div className={s.model_selection_label_2}>
                                    {/* {modelName} */}
                                    {currentModelDescription.currentModelName}
                                </div>
                            </div>) : null
                    )
                })}

                {/* <UndefinedModel /> */}

            </div>

        </div>
    }</>)
}