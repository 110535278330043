import { useSelector } from "react-redux";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import s from "./checkPromo.module.scss";
import { checkPromoThunk } from "../../../store/features/productThunk";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ProductType } from "../../../store/features/productTypes";
import { TextField } from "../../../components/new-text-field/text-field";
import { Button } from "../../../components/button/button";
import ArrowRight from "../../../assets/icons/arrowRight";
import { ButtonVariant } from "../../../store/storeConst/enums";
import { productsActions } from "../../../store/features/productSlice";
import { yandexMetrikaClickEvent } from "../../../common/utils/yadexMetrikaCounter";
import { appSelectors } from "../../../store/features/app/app-selectors";
import { appActions } from "../../../store/features/app/app-slice";

const CheckPromoCode = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const promoCode = useAppSelector(appSelectors.getPromo());

  const products: Array<ProductType> = useSelector(
    (state: RootState) => state.product.products
  );

  const isPromoCodeLoading: boolean = useSelector(
    (state: RootState) => state.product.loadingVars.isPromoCheckLoading
  );

  const checkPromoClickHandler = () => {
    // yandexMetrikaClickEvent("TT_11_check_promo_click");
    if (promoCode.length > 0) {
      dispatch(productsActions.activePromoCode(promoCode));
      dispatch(checkPromoThunk({ promoCode, navigate, products }));
    } else {
      toast.error("Необходимо ввести промокод");
    }
  };

  return (
    <div className={s.promo_div} id={"promoContainer"}>
      <TextField
        value={promoCode}
        placeholder={"Промокод (если имеется)"}
        isError={false}
        className={s.PromoInput}
        onValueChange={(value) => dispatch(appActions.setPromo(value))}
      />
      <Button
        variant={ButtonVariant.Secondary}
        onClick={checkPromoClickHandler}
        className={s.button}
        disabled={isPromoCodeLoading}
      >
        <ArrowRight />
      </Button>
    </div>
  );
};

export default CheckPromoCode;
